module.exports = {
  siteTitle: 'Ryan Guan',
  siteDescription: `I'm a student at University of Pennsylvania studying computer science and operations management. \
  I like to read, sail, and play video games in my free time.`,
  keyWords: ['computer science', 'research'],
  authorName: 'Ryan Guan',
  githubUsername: 'rguan1',
  authorAvatar: '/images/headshot.jpg',
  authorDescription: 'Hi! I\'m currently a senior at the University of Pennsylvania studying computer science and \
  operations management. I\'m from Boston, MA. In my free time, I like to sail, read, and play video games.',
  education: [
    {
      name: "University of Pennsylvania",
      classYear: "Class of 2021",
      degrees: [
        {
          title: "School of Engineering and Applied Sciences",
          major: "Computer Science"
        },
        {
          title: "The Wharton School",
          major: "Operations Management"
        }
      ]
    },
    {
      name: "Buckingham Browne and Nichols School",
      classYear: "Class of 2017",
      degrees: null
    }
  ],
  jobs: [
    /* more jobs here */
    {
      company: "Bloomberg",
      begin: {
        month: 'June',
        year: '2021'
      },
      duration: "2 months and 2 weeks",
      occupation: "Software Engineer Intern",
      logo: "/images/bloomberg_logo.jpg",
      description: "As part of the static market data services team, I designed and implemented a workflow for an automated \
      root cause analysis (RCA) tool to process support tickets by integrating a server-side search application in Lua with \
      a front-end application in TypeScript. This tool reduces the number of manually processed tickets by 20% and easily \
      allows addition of new automated RCA cases through a generic framework."
    },
    {
      company: "UPenn's Computer Information Science Department",
      begin: {
        month: 'aug',
        year: '2020'
      },
      duration: "4 months",
      occupation: "Machine Learning TA",
      logo: "/images/upenn_logo.png",
      description: "I advised students on their zero-shot bird classification final project, held office hours, designed exam and problem \
      set questions, and graded homeworks."
    },
    {
      company: "Bloomberg",
      begin: {
        month: 'may',
        year: '2020'
      },
      duration: "2 months and 2 weeks",
      occupation: "Software Engineer Intern",
      logo: "/images/bloomberg_logo.jpg",
      description: "As a part of the telemetry infrastructure team, I built and deployed jupyter notebook widgets to visualize \
       distributed trace data by leveraging OSS python interactive widgets. In addition, I completed a proof of concept tool for \
       dynamic visualizations of large scale visualizations of distributed trace data and presented my findings to internal teams and senior management."
      
    }, {
      company: "Broad Institute of MIT and Harvard",
      begin: {
        month: 'may',
        year: '2019'
      },
      duration: '3 months',
      occupation: "Software Development Intern",
      logo: "/images/broad_logo.png",
      description: "I designed a real-time debugging GUI using React and Cytoscape.js for Cromwell scientific workflows. \
      This tool unified two separate sources of workflow progress data into a single comprehensive and visual source which \
      accelerates the pace of cancer research."
    },  {
      company: "Department of Neuroscience at UPenn (Kording Lab)",
      begin: {
        month: 'Sep',
        year: '2018'
      },
      duration: "1 year and 1 month",
      occupation: "Research Assistant",
      logo: "/images/upenn_logo.png",
      description: "I worked on a research project that developed a theoretical model of biological perception using deep \
      neural networks. This work culminated in a presentation at the 2019 Conference on Cognitive Computational Neuroscience \
      called 'Shared visual illusions between humans and artificial neural networks'"
  
    }, {
      company: "BankMobile",
      begin: {
        month: 'May',
        year: '2018'
      },
      duration: '3 months',
      occupation: "Software Development Intern",
      logo: "/images/bankmobile_logo.png",
      description: "I worked on creating banking assistant apps on Google Assistant, Alexa, and Microsoft Bot Framework using NLP, Node.js, and Azure."
    },
    {
      company: "Schepens Eye Research Institute (Chen Lab)",
      begin: {
        month: 'May',
        year: '2015'
      },
      duration: '2 years',
      occupation: "Research Assistant",
      logo: "/images/schepens_logo.png",
      description: "I automated data collection saving more than 1000-man hours as well as removing selection bias from \
      data. I also co-authored the paper: “Ezh2 does not mediate retinal ganglion cell homeostasis or their susceptibility \
      to injury,” in PLOS One."
    },
  ],
  portfolio: [
    {
      image: "/images/upennsfcu_website.png",
      description: "UPenn SFCU Website",
      url: "https://upennsfcu.org/"
    },
    {
      image: "/images/upennsfcu_form.png",
      description: "UPenn SFCU Forms",
      url: "https://forms.upennsfcu.org/apply/apply-member"
    },
    {
      image: "/images/mini_minecraft_project_3.png",
      description: "Mini Minecraft",
      url: "https://drive.google.com/file/d/1Po-DkoYQ7XqclrBP9G2ZifqYPVY9Wp8i/view"
    },
    {
      image: "/images/brainwayve_example.png",
      description: "BrainWayve - ML Powered Audio Editor",
      url: "https://docs.google.com/presentation/d/e/2PACX-1vTzMbOnOUcY0eNX9oHvZ801F4B5d2MDCSUxQlaqXQ84JEJq2BOoEIGoAQ9zCYF-W8JHLtrJ_rhQhZ5a/pub?start=false&loop=true&delayms=60000#slide=id.p"
    },
    {
      image: "/images/workflow_visualizer.png",
      description: "Cromwell Workflow Visualizer",
      url: "https://github.com/rguan1/Workflow-Visualizer"
    },
    {
      image: "/images/gene_silencing_project.png",
      description: "Classifying Gene Silencing \n From Cell Images Via Transfer Learning",
      url: "/documents/classifying_gene_silencing.pdf"
    }
  ],
  social: {
    linkedin: "https://www.linkedin.com/in/ryan-guan/",
    // github: "https://github.com/rguan1",
  },
  siteUrl: 'https://ryanmguan.com',
  pathPrefix: '/ryan-guan-website', // Note: it must *not* have a trailing slash.
  siteCover: '/images/banner_boat.jpg',
  googleAnalyticsId: 'UA-175421615-1',
  background_color: '#ffffff',
  theme_color: '#25303B',
  fontColor: "#000000cc",
  enableDarkmode: true, // If true, enables dark mode switch
  display: 'minimal-ui',
  icon: 'src/assets/logo.png',
  headerLinks: [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: 'Portfolio',
      url: '/portfolio',
    }
  ]
}